import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Form from 'react-bootstrap/Form';
import axios from 'axios';


function GetCode() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [organization, setOrganization] = useState('');
  const [email, setEmail] = useState('');
  const [telegram, setTelegram] = useState('');
  const [error, setError] = useState(null);
  const [getCodeCompleted, setGetCodeCompleted] = useState(false);

  async function handleGetCode() {
    try {
      const response = await axios.post('/api/getcode', {
        email,
        organization,
        telegram
      });

      if (response.status === 200) {
        setGetCodeCompleted(true);
        setTimeout(() => {
          handleClose();
        }, 5000);
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      console.error(error.response.data.message);
      setError(error.response.data.message);
    }
  }

  return (
    <>
      <Button onClick={handleShow}>
        Пробовать бесплатно
      </Button>

      <Offcanvas className="get-code" show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="form-get-code">
            {getCodeCompleted ? (
              <div>
                <h3 className="text-center mb-3">Запрос отправлен</h3>
              </div>
            ) : (
              <div>
                {error && <div className="text-center get-code-error">{error}</div>}
                <div>
                  <h3 className="text-center mb-5">Оставьте заявку</h3>
                </div>
                <Form>
                  <Form.Group className="mb-3" controlId="organization">
                    {/* <Form.Label>Название организации</Form.Label> */}
                    <Form.Control
                      type="text"
                      placeholder="Название организации"
                      value={organization}
                      onChange={(e) => setOrganization(e.target.value)}
                      className='form-control-input'
                      autoComplete='off'
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="email">
                    {/* <Form.Label>Электронная почта</Form.Label> */}
                    <Form.Control
                      type="email"
                      placeholder="Электронная почта"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className='form-control-input'
                      autoComplete='off'
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="email">
                    {/* <Form.Label>Имя пользователя Telegram</Form.Label> */}
                    <Form.Control
                      type="text"
                      placeholder="Имя пользователя Telegram"
                      value={telegram}
                      onChange={(e) => setTelegram(e.target.value)}
                      className='form-control-input'
                      autoComplete='off'
                    />
                  </Form.Group>
                  <Button variant="warning" className='get-code-btn mt-3' onClick={handleGetCode}>
                    Отправить
                  </Button>
                </Form>
              </div>
            )}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default GetCode;
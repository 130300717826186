import React from "react";
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";

class FooterApps extends React.Component {
    render() {
        return (
            <Container fluid className="footer-apps">
                <Container>
                    <Row className="row-footer">
                        <Col md={4} lg={4}>
                            <h2>WorkerNet</h2>
                            <p>info@apelsin-it.com</p>
                            <p><a href="https://t.me/us_task" target="_blank" rel="noreferrer">Телеграмм</a></p>
                            <p>8 (34345) 47-2-47</p>
                            <p>г. Нижняя Салда, ул. Советская, 4</p>
                        </Col>
                        <Col md={4} lg={4}>
                            <p><Link to="/appdoc">Информационные материалы</Link></p>
                            <p><Link to="/wiki">База знаний</Link></p>
                            <p><Link to="/bill">Кабинет оператора</Link></p>
                        </Col>
                        <Col md={4} lg={4}>
                            <p>Мобильное приложение для операторов связи</p>
                            <p>Заявки монтажников</p>
                            <Button>Скачать</Button>
                        </Col>
                    </Row>
                </Container>
            </Container>
        )
    }
}


export default FooterApps;
import React from "react";
import { Container } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import pdf from '../assets/pdf.png'
import FooterApps from "../componets/FooterApps";

class Appdoc extends React.Component {

    render() {
        return (
            <>
                <Container fluid className="container-appdoc d-flex align-items-center">
                    <Container>
                        <Row className="row-row-appdoc align-items-center">
                            <Row className="align-items-center">
                                <Col xs={2} sm={2} md={2} lg={1} className="img-pdf">
                                    <img src={pdf} height="50" />
                                </Col>
                                <Col xs={10} sm={10} md={10} lg={11}>
                                    <p>Инструкция по скачиванию и установке</p>
                                </Col>
                            </Row>
                            <Row className="align-items-center">
                                <Col xs={2} sm={2} md={2} lg={1} className="img-pdf">
                                    <img src={pdf} height="50" />
                                </Col>
                                <Col xs={10} sm={10} md={10} lg={11}>
                                    <p>Описание жизненного цикла</p>
                                </Col>
                            </Row>
                            <Row className="align-items-center">
                                <Col xs={2} sm={2} md={2} lg={1} className="img-pdf">
                                    <img src={pdf} height="50" />
                                </Col>
                                <Col xs={10} sm={10} md={10} lg={11}>
                                    <p>Описание ПО (описание функциональных характеристик)</p>
                                </Col>
                            </Row>
                        </Row>
                    </Container>
                </Container>
                
                <FooterApps />
        </>
        )
    }
}

export default Appdoc;
import React from "react";
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import CarouselAppsHeader from "../componets/CarouselAppsHeader"
import CarouselAppsScope from "../componets/CarouselAppsScope"
import CardsApps from "../componets/CardsApps";
import GetCode from "../componets/GetCode";
import FooterApps from "../componets/FooterApps";
import gplay from "../assets/gplay.svg"

class Apps extends React.Component {

    render() {
        return (
            <>
                <Container fluid>
                    <Row className="row-apps">
                        <Col lg={6} className="header-apps-name order-2 order-lg-1">
                            <Row className="align-items-center h-100">
                                <Col>
                                    <div className="apps-title">
                                        <h4>Мобильное приложение</h4>
                                        <span className="apps-title-h1-1"><h1>Заявки</h1></span><span><h1 className="apps-title-h1-2">монтажников</h1></span>
                                        <p>Мобильное приложение для взаимодействия с комплексом UserSide</p>
                                        <Link to="/gpay"><img src={gplay} /></Link>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={6} className="header-carousel-apps order-1 order-lg-2">
                            <CarouselAppsHeader />
                        </Col>
                    </Row>
                </Container>

                <Container className="scope-apps d-none d-lg-block">
                    <Row className="row-scope flex-center">
                        <Col className="text-center">
                            <h2>Возможности приложения</h2>
                        </Col>
                    </Row>
                    <Row className="row-scope align-items-center">
                        <Col lg={4}>
                            <CarouselAppsScope />
                        </Col>
                        <Col lg={8}>
                            <CardsApps />
                        </Col>
                    </Row>
                </Container>

                <Container fluid className="scope-apps scope-apps-fluid d-block d-lg-none">
                    <Row className="row-scope flex-center">
                        <Col className="text-center">
                            <h2>Возможности приложения</h2>
                        </Col>
                    </Row>
                    <Row className="row-scope align-items-center justify-content-center">
                        <Col>
                            <CardsApps />
                        </Col>
                        <Row className="row-scope row-scope-fluid align-items-center justify-content-center">
                            <Col>
                                <h2>Скриншоты приложения</h2>
                                <CarouselAppsScope />
                            </Col>
                        </Row>
                    </Row>
                </Container>

                <Container fluid className="features-apps">
                    <Container>
                        <Row className="row-features flex-center">
                            <Col className="text-center">
                                <h2>Особенности приложения</h2>
                            </Col>
                        </Row>
                        <Row className="row-features">
                            <Col lg={6}>
                                <p>- Полная интеграция с програмным комплексом UserSide;</p>
                                <p>- GPS контроль заявок и пробега;</p>
                                <p>- Просмотр списка заданий доступных сотруднику;</p>
                                <p>- Просмотр полного описания каждого задания из UserSide (абонент, адрес, контакты, дополнительные данные);</p>
                                <p>- Просмотр комментариев к заданию;</p>
                                <p>- Изменить статус задания на выполняется и закрыть;</p>
                                <p>- Отправка комментария в задании;</p>
                                <p>- Отправка фото, к заданию;</p>
                                <p>- Просмотр оборудования закрепленного за сотрудником;</p>
                            </Col>
                            <Col lg={6}>
                                <p>- Просмотр коммутации узла, муфты, прикрепленных к заданию;</p>
                                <p>- Получение уровня сигнала, подключенной ONU (PON);</p>
                                <p>- Авто синхронизация списка заданий с UserSide;</p>
                                <p>- Автономная работа при отсутствии подключения к внешней сети интернет;</p>
                                <p>- Возможность сразу позвонить абоненту из карточки задания;</p>
                                <p>- Возможность прикрепить фото к заданию сразу с камеры устройства;</p>
                                <p>- При отсутствии интернета можно повторно отправить прикрепленное фото;</p>
                            </Col>
                        </Row>
                    </Container>
                </Container>

                <Container fluid className="price-apps">
                    <Container>
                        <Row className="row-price align-items-center flex-center">
                            <Col md={8} lg={9}>
                                <h2>Попробуйте и посмотрите, как просто использовать приложение Заявки монтажников</h2>
                            </Col>
                            <Col md={4} lg={3}>
                                <GetCode />
                            </Col>
                        </Row>
                    </Container>
                </Container>

                <FooterApps />
            </>
        )
    }
}

export default Apps;